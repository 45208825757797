"use client";

import React from 'react';
import dayjs from '@/lib/dayjs/dayjs';

import useSWR from 'swr';
import { getResponsiblePharmacist } from './actions/getResponsiblePharmacist';
import ResponsiblePharmacist from './ResponsiblePharmacist';



/**
 * Displays the responsible Pharmacist Found in the footer.
 * @param props
 * @returns {JSX.Element}
 */
const ResponsiblePharmacistContainer = (): JSX.Element | null => {
    // Gets the responsible pharmacist for the footer.
    const {
        isLoading,
        data,
    } = useSWR(
        `responsible-pharmacist`,
        async () => getResponsiblePharmacist(),
        {
            revalidateOnFocus: true,
            revalidateOnReconnect: true,
            refreshWhenHidden: false,
            refreshWhenOffline: false,
            revalidateIfStale: false,
            revalidateOnMount: true,
        }
    );


    const responsiblePharmacist =  data?.responsiblePharmacist;

    // Get the current date in format.
    const today = dayjs().local().format('YYYY-MM-DD');

    // If it's not the weekend and the time is between 08:30 and 14:00.
    const isDisplayTimeSlot = dayjs().local().isoWeekday() <= 5 && dayjs().local().isBetween(`${today} 08:30:00`, `${today} 17:00:00`);

    // If it's the wrong time to display the Responsible Pharmacist or there is not one set, don't display.
    if (!isDisplayTimeSlot || !responsiblePharmacist) {
        return null;
    }

    const ResponsiblePharmacistName = `${responsiblePharmacist?.firstName} ${responsiblePharmacist?.lastName}`;

    return (
        <ResponsiblePharmacist
            isLoading={isLoading}
            responsiblePharmacist={responsiblePharmacist}
            ResponsiblePharmacistName={ResponsiblePharmacistName}
        />
    );
};

export default ResponsiblePharmacistContainer;
