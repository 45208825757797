"use client";

import React from 'react';

import Typography from '../../../_ui/_blocks/Typography/Typography';
import Avatar from '../../../Avatar/Avatar';
import { type ResponsiblePharmacist } from './actions/getResponsiblePharmacist';

export interface Props {
    isLoading: boolean;
    responsiblePharmacist?: ResponsiblePharmacist;
    ResponsiblePharmacistName?: string;
}

/**
 * Displays the responsible Pharmacist Found in the footer.
 * @param props
 * @returns {JSX.Element}
 */
const ResponsiblePharmacist = ({
    isLoading,
    responsiblePharmacist,
    ResponsiblePharmacistName,
}: Props): JSX.Element | null => {
   
    return (
        <div className={`md:mt-200 lg:mt-0 ${isLoading ? 'invisible' : ''}`}>
            <Typography as="h5" typeset="subtitle" size="200" color="layers-highlight">
                Today's responsible Pharmacist
            </Typography>
            <a
                href={`/about/meet-the-team/${responsiblePharmacist?.slug.current}`}
                className="flex items-center gap-050 mt-025 type-090 lg:mt-100"
            >
                <Avatar
                    src={responsiblePharmacist?.profileImage?.asset?.url}
                    alt={responsiblePharmacist?.profileImage?.asset?.altText || ResponsiblePharmacistName || ''}
                    size="small"
                    fallback="/images/profile.svg"
                />
                {ResponsiblePharmacistName}
            </a>
        </div>
    );
};

export default ResponsiblePharmacist;
